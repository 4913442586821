import React from "react"
import SEO from "../../../components/seo"
// import { Helmet } from "react-helmet"
import Layout from "../../../components/layout"
import TwoColumn from "../../../components/two-column"
import CustomerJourney from '../../../components/customer-journey'
import Preview from '../../../components/preview'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCheckCircle, faChevronRight, faPlay } from '@fortawesome/free-solid-svg-icons'

const bannerContent = {
  title: "Simple and flexible checkout to start creating subscriptions quickly.",
  description: [
    {
      text: "You can implement Billsby Checkout on your website with as little as two lines of code, and then use advanced features to customize the experience and make it even more advanced. Or use our card tokenizer for complete control."
    }
  ],
  buttons: [
    {
      isExternal: true,
      url: 'https://app.billsby.com/registration',
      buttonColor: 'orange',
      buttonText: 'Sign up for free'
    },
    {
      scheduleButton: true,
      buttonColor: "black"
    }
  ],
  // sectionDevelopers: true,
  // sectionSubscriberBanner: true,
  sectionFeaturetag: true,
  svg: true,
  image: require('../../../images/checkout-payments.svg'),
  imageName: 'checkout payments svg',
  backgroundColor: '#f3f5fb',
  textColor: '#252422',
  imagePosition: 'right'
}

const interactivePreviewContent = {
  title: "The best way to experience Billsby Checkout is to try it",
  description: "Choose the 'Subscribe today' button below to see Billsby Checkout in action - including advanced features like custom fields, address validation and plan selection. Don't worry, you won't be charged and we regularly purge accounts.",
  preview: 
  <>
    <div className="preview interactive-preview">
      <div className="preview-container">
        <div className="col-2" style={{ backgroundImage: `url(${require('../../../images/preview-img1@2x.png')})`}}>
        </div>
        <div className="col-2 gray-bg">
          <p className="preview-text">
            Beautiful <br/>
            swimwear <br/>
            delivered monthly.
          </p>
          <h4 className="preview-heading">Beach</h4>
          <a href="javascript:void(0)" data-billsby-type="checkout" className="btn btn-violet preview-button">Subscribe today</a>
        </div>
        <div className="col-3" style={{ backgroundImage: `url(${require('../../../images/preview-img2@2x.png')})`}}>
        </div>
        <div className="col-3" style={{ backgroundImage: `url(${require('../../../images/preview-img3@2x.png')})`}}>
        </div>
        <div className="col-3" style={{ backgroundImage: `url(${require('../../../images/preview-img4@2x.png')})`}}>
        </div>
      </div>
    </div>

    <div className="text-holder">
      <p className="text">The entire checkout experience you saw above is delivered with just two lines of code - some JavaScript in the header, and a link to launch the modal. Customers never leave your website and the whole experience remains on-brand, quick and simple.</p>
    </div>

    <div className="code-holder">
      <pre>
       <code>
       &lt;script src="https://checkoutlib.billsby.com/checkout.min.js" data-billsby-company="<span className="orange-text">companyname</span>">&lt;/script>
       </code>
      </pre>
    </div>

    <div className="code-holder">
      <pre>
       <code>
       &lt;a href="javascript:void(0)" data-billsby-type="checkout">Subscribe&lt;/a>
       </code>
      </pre>
    </div>
    
    <div className="text-holder">
      <p className="text">In short, if you can copy and paste two lines of code you can implement Billsby Checkout on your website.</p>
    </div>
  </>
}

const previewContent = {
  title: "Save customers time and take more control with data passthrough",
  description: "If you've got a little time, you can use data passthrough to take more control of your checkout experience - passing through customer information, plan selections and answers to custom fields before they subscribe.",
  backgroundColor: '#F3F5FB',
  browserColor: 'black',
  blackBrowser: true,
  preview: 
  <>
    <div className="preview interactive-preview black-browser disabled">
      <div className="preview-container">
        <div className="col-2" style={{ backgroundImage: `url(${require('../../../images/preview-img1@2x.png')})`}}>
        </div>
        <div className="col-2 gray-bg">
          <p className="preview-text">
            Beautiful <br/>
            swimwear <br/>
            delivered monthly.
          </p>
          <h4 className="preview-heading">Beach</h4>
          <a href="javascript:void(0)" data-billsby-type="checkout" className="btn btn-violet preview-button custom-preview-button">
            Welcome back Georgia
            <br/> 
            <span>Resubscribe today on the same plan</span>
          </a>
        </div>
        <div className="col-3" style={{ backgroundImage: `url(${require('../../../images/preview-img2@2x.png')})`}}>
        </div>
        <div className="col-3" style={{ backgroundImage: `url(${require('../../../images/preview-img3@2x.png')})`}}>
        </div>
        <div className="col-3" style={{ backgroundImage: `url(${require('../../../images/preview-img4@2x.png')})`}}>
        </div>
      </div>
    </div>

    <div className="text-holder">
      <p className="text">Customer details can be passed to Billsby Checkout using a simple JavaScript array, and the product, plan and cycle embedded into the subscribe link - which also allows you to create custom price tables and plan selection journeys.</p>
    </div>

    <div className="code-holder">
      <pre>
       <code>
       &lt;script> 
       <br/> &nbsp;window.billsbyData = &#123; 
       <br/> &nbsp; firstName: "Georgia", 
       <br/> &nbsp; lastName: "Knowles", 
       <br/> &nbsp; email: "georgiaknowles@mistimail.com", 
       <br/> &nbsp; billingAddressLine1: "2000 Montego Ct", 
       <br/> &nbsp; billingAddressCity: "Oldsmar",
       <br/> &nbsp; billingAddressState: "FL",
       <br/> &nbsp; billingAddressZip: "34677",
       <br/> &nbsp; billingAddressCountry: "USA",
       <br/> &nbsp; shippingAddressLine1: "2000 Montego Ct",
       <br/> &nbsp; shippingAddressCity: "Oldsmar",
       <br/> &nbsp; shippingAddressState: "FL",
       <br/> &nbsp; shippingAddressZip: "34677",
       <br/> &nbsp; shippingAddressCountry: "USA",
       <br/> &nbsp; phoneNumberDialCode: "1",
       <br/> &nbsp; phoneNumber: "8881234567", 
       <br/> &nbsp; phoneNumberDialCountry: "US",
       <br/> &nbsp; marketingConsent: true, 
       <br/> &nbsp; customFields: [&#123; 
       <br/> &nbsp;&nbsp;&nbsp; customFieldId: 32, 
       <br/> &nbsp;&nbsp;&nbsp;&nbsp;value: "white,purple,orange,gold" 
       <br/> &nbsp;&nbsp;&#125; 
       <br/> &nbsp;&nbsp;&#123; 
       <br/> &nbsp;&nbsp;&nbsp;customFieldId: 31, 
       <br/> &nbsp;&nbsp;&nbsp;value: "8" 
       <br/> &nbsp;&nbsp;&#125;] 
       <br/> &#125;; 
       <br/>&lt;/script>
       </code>
      </pre>
    </div>

    <div className="code-holder">
      <pre>
       <code>
       &lt;a href="javascript:void(0)" data-billsby-type="checkout" data-billsby-product="<span className="orange-text">ProductID</span>" data-billsby-plan="<span className="orange-text">PlanID</span>" data-billsby-cycle="<span className="orange-text">CycleID</span>">Subscribe&lt;/a>
       </code>
      </pre>
    </div>
  </>
}

const featuresContent = {
  title: "Billsby Checkout is packed with all the features you need.",
  list: [
    {
      text: <>Global tax calculations, currency support and address validation</>
    },
    {
      text: <>Fully PCI-DSS compliant - you never see or collect the card number</>
    },
    {
      text: <>Unlimited custom fields for advanced data collection</>
    },
    {
      text: <>Embedded so the customer never leaves your website</>
    },
    {
      text: <>Future proof for new payment methods, plans and regulations</>
    },
  ],
  image: 'features.png',
  imageName: 'features',
  backgroundColor: '#243F5C',
  textColor: '#fff',
  imagePosition: 'right',
  sectionFeatures: true
}

const customerJourneyContent = {
  title: "Or build your own checkout with our card tokenizer.",
  backgroundColor: '#fff',
  list: [
    {
      title: 'Maintain PCI-DSS compliance',
      description: "Whilst our card tokenizer fields look like any other form field on your website, you won't touch, process or store cardholder data.",
    },
    {
      title: 'Create your own custom experience',
      description: "Using our API, you can create completely custom checkout and on-boarding experiences that look and feel exactly how you'd like"
    },
    {
      title: 'Switch at any time',
      description: "Start with Billsby Checkout whilst you get up and running, then swap to the card tokenizer at any time. You're in control."
    }
  ],
  svg: true,
  image: require('../../../images/stories_illustrations_payment.svg'),
  imageName: 'stories illustrations payment',
  imagePosition: 'bottom'
}

const CheckoutandPayment = () => (
  <Layout className="subscriber checkout-and-payment">
    <SEO 
      title="Checkout and payments | Billsby | Powerful, customizable subscription billing software" 
      description="Simple drop-in Javascript lets you embed a full subscription billing and recurring payments checkout into your website in two lines of code." 
      url="https://www.billsby.com/product/subscribers/checkout-and-payments"
    />

    <TwoColumn content={bannerContent}/>
    <Preview content={interactivePreviewContent}/>
    <Preview content={previewContent}/>
    <TwoColumn content={featuresContent}/>
    <CustomerJourney content={customerJourneyContent} />
  </Layout>
)

export default CheckoutandPayment