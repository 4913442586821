import React from "react"
// import { Link } from "gatsby"
import classNames from 'classnames'
import Img from "../components/image-transform"

const CustomerJourney = ({content}) => {

  return (
    <div 
      className={
        classNames("section-customer-journey", 
        {right: content.bgPosition === 'right'},
        {bottom: content.imagePosition === 'bottom'}
        )
      }
      style={{ backgroundColor: content.backgroundColor, backgroundImage: `url(${content.backgroundImage})`}}>
      <div className="container">
    

        <div className="content-wrapper">
          {
            content.align === 'right' &&
            <div className={
              classNames('image-holder right', {
                hasBg: content.hasBg === true
              })
            }>
              {
                content.svg ?
                <img src={content.image} className="image" alt={content.imageName}/>
                :
                <Img filename={content.image} className="image" alt={content.imageName}/>
              }
            </div>
          }

          <div className={classNames(
            'text-holder', {
              right: content.align === 'right'
            }
          )}>
            <h2 className="title">{content.title}</h2> 
            <div className="list">
              {content.list.map((item, i) => {
                return (
                  <div key={i} className="list-item">
                    <h3 className="list-item-title">{item.title}</h3>
                    <p className="list-item-description">{item.description}</p>
                      {
                      item.buttons !== undefined && 
                      <div className="btn-wrapper">
                        {
                          item.buttons.map((btn, i) => {
                            return (
                                <a href={btn.url}
                                  key={i}
                                  className={
                                  classNames('btn c', {
                                    'btn-blue' : btn.btnColor === 'blue',
                                    'btn-orange' : btn.btnColor === 'orange',
                                    'btn-black' : btn.btnColor === 'black',
                                  }
                                )}>
                                  { btn.btnText }
                                </a>
                            )
                          })
                        }
                      </div>
                    }
                  </div>
                )
              })}
            </div>
            {
              content.btnText !== undefined && 
              <div className="btn-wrapper">
              <a className={classNames({
                adoric_video_featuretags: content.btnClass === 'adoric_video_featuretags',
                adoric_video_allowances: content.btnClass === 'adoric_video_allowances',
                adoric_video_products: content.btnClass === 'adoric_video_products'
              }, "btn-play")}> 
                <img 
                  src={require('../images/play.svg')}
                  className="play-svg"
                  alt="play"
                />
                {content.btnText} 
              </a>
            </div>
            }
          </div>
          
          {
            content.image && content.align !== 'right' &&
            <div className={
              classNames('image-holder', {
                hasBg: content.hasBg === true
              })
            }>
              {
                content.svg ?
                <img src={content.image} className="image" alt={content.imageName}/>
                :
                <Img filename={content.image} className="image" alt={content.imageName}/>
              }
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default CustomerJourney