import React from "react"
// import { Link } from "gatsby"
import classNames from "classnames"

const Preview = ({ content, className }) => {
  return (
    <div
      className={`section-preview${className ? ` ${className}` : ""}`}
      style={{ backgroundColor: content.backgroundColor }}
    >
      <div className="container">
        <h2 className="title">{content.title}</h2>
        <p className="description">{content.description}</p>
        <div
          className={classNames(
            "preview-wrapper",
            { "preview-wrapper-black-browser": content.blackBrowser === true },
            {
              "preview-wrapper-black-bg":
                content.previewBlackBackground === true,
            }
          )}
        >
          {content.preview}
        </div>
      </div>
    </div>
  )
}

export default Preview
