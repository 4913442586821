import React from "react"
import { Link } from "gatsby"
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCheckCircle, faChevronRight, faPlay, faChevronCircleRight } from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle, faPlay } from '@fortawesome/free-solid-svg-icons'
import ScheduleADemoButton from '../components/schedule-a-demo-button'
import Img from "../components/image-transform"
const TwoColumn = ({content}) => {
  function handleClick() {
    window.Intercom('show');
	}
  return (
    <div
      className={classNames('section-two-column', 
      {"background-image-right" : content.backgroundPosition === "right"},
      {"image-full-right" : content.imagePosition === "full-right"},
      {"image-left" : content.imagePosition === "left"},
      {"image-right" : content.imagePosition === "right"},
      {"section-pro-development-banner" : content.sectionProDevelopmentBanner === true},
      {"section-protection" : content.sectionProtection === true},
      {"section-flowers" : content.sectionFlowers === true},
      {"section-developers" : content.sectionDevelopers === true},
      {"section-solutions" : content.sectionSolutions === true},
      {"section-home-banner" : content.sectionHomeBanner === true},
      {"with-promotion" : content.withPromotion === true},
      {"section-system" : content.sectionSystem === true},
      {"section-button-generator-banner" : content.generatorBanner === true},
      {"solution-saas" : content.solutionSaas === true},
      {"healthcare-and-education" : content.healthcareAndEducation === true },
      {"iot" : content.healthcareAndEducation === true },
      {"section-carbon-banner" : content.sectionCarbonBanner === true},
      {"section-credit-repair-banner" : content.sectionCreditRepairBanner === true},
      {"section-home-banner2" : content.sectionHomeBanner2 === true},
      {"section-plan-action-in-action" : content.sectionPlanActionInAction === true},
      {"section-zapier" : content.sectionZapier === true},
      {"section-subscriber-banner" : content.sectionSubscriberBanner === true},
      {"section-feature-tag" : content.sectionFeaturetag === true},
      {"section-has-image-description" : content.hasImageDescription === true},
      {"section-page-banner" : content.sectionPageBanner === true},
      {"section-report" : content.sectionReport === true},
      {"section-value-score" : content.valueScore === true },
      {"brand-identity" : content.brandIdentity === true},
      {"section-reverse" : content.sectionReverse === true},
      {"section-report-2" : content.sectionReport2 === true},
      {"section-report-3" : content.sectionReport3 === true},
      {"section-report-4" : content.sectionReport4 === true},
      {"section-features" : content.sectionFeatures === true },
      {"section-invoice-banner" : content.sectionInvoiceBanner === true },
      {"section-service" : content.sectionService === true },
      {"section-payment-gateways" : content.sectionPaymentGateways === true },
      {"section-reason-to-stay" : content.sectionReasonToStay === true },
      {"section-calculator" : content.sectionCalculator === true },
      {"section-security" : content.sectionSecurity === true },
      {"section-accounting" : content.sectionAccounting === true },
      {"section-standard" : content.sectionStandard === true }
    )} style={{ backgroundColor: content.backgroundColor, backgroundImage: `url(${content.backgroundImage})`, color: content.textColor}}>
      {content.hasBg ? <div className="bg"></div> : ''}
      <div className="container">
        {
          content.promotionTitle 
          ?
          !content.promotionURLText 
          ?
          <div className="promotion promotion-two-col">
            <div className="promotion-wrapper">
              {
                content.promotionBadge
                ?
                <div className="badge-holder">
                  <h3 className="promotion-badge">{content.promotionBadge}</h3>
                </div>
                :
                ''
              }
              <p className="promotion-title">{content.promotionTitle}</p>
            </div>
          </div>
          :
          <div className="promotion">
            {
              content.promotionBadge
              ?
              <div className="badge-wrapper">
                <h3 className="promotion-badge">{content.promotionBadge}</h3>
              </div>
              :
              ''
            }
            <p className="promotion-title">{content.promotionTitle}</p>
            {content.promotionURLText
            ?
            <Link to={content.promotionURL} className="promotion-link">{content.promotionURLText}</Link>
            :
            '' 
            }
          </div>
          :
          ''
        }
        <div className="content-wrapper">
          <div className="text-holder">
            {content.title && 
              content.isBanner === true
              ?
              <h1 className="title">{content.title}</h1>
              :
              <h2 className="title">{content.title}</h2>
            }
            {content.description && 
              content.description.map((item, i) => {
                return (
                  <p className="description" key={i}>{item.text}</p> 
                ) 
               }
              )
            }
            {
              content.list &&
              <ul className="list">
                {
                  content.list.map((item, i) => {
                    return (
                      <li className="item" key={i}>
                        <p><FontAwesomeIcon icon={faCheckCircle} className="icon" /> {item.text}</p>
                      </li>
                    )
                  })
                }
              </ul>
            }
            {content.hasImageDescription === true &&
              <div className="image-below"> 
                {content.imageDescription &&
                content.svgImageDescription ? 
                <img src={content.imageDescription} className="image" alt={content.imageDescriptionName}/> 
                :
                <Img filename={content.imageDescription} className="image" alt={content.imageDescriptionName}/>
              }
              </div>
            }
            {content.buttons !== undefined  &&
              <div className="button-holder">
                {content.buttons.map((item, i) => {
                  return (
                    item.scheduleButton ?
                      <ScheduleADemoButton key={i} buttonColor={item.buttonColor}/>
                      :
                        item.intercom ?
                          <button key={i}
                            className={classNames('btn', 
                              {"intercom-prodevelopment-top" : item.itercomTop === true},
                              {"intercom-prodevelopment-bottom" : item.itercomBottom === true},
                              {"btn-orange" : item.buttonColor === "orange"},
                              {"btn-white" : item.buttonColor === "white"},
                              {"btn-black" : item.buttonColor === "black"}
                          )}>{item.buttonText}</button>           
                      :
                      item.isExternal ?
                        <a href={item.url} key={i} target="_blank" rel="noopener noreferrer"
                        className={classNames('btn', 
                          {"btn-orange" : item.buttonColor === "orange"},
                          {"btn-white" : item.buttonColor === "white"},
                          {"btn-black" : item.buttonColor === "black"}
                        )}>{item.buttonText}</a> 
                      :
                        <Link to={item.url} key={i}
                        className={classNames('btn', 
                          {"btn-orange" : item.buttonColor === "orange"},
                          {"btn-white" : item.buttonColor === "white"},
                          {"btn-black" : item.buttonColor === "black"}
                      )}>{item.buttonText}</Link> 
                  )
                })}
              </div>
            }
            {content.authorize !== undefined && 
              <React.Fragment>
                {
                  content.authorizeIsClickable !== undefined && !content.authorizeIsClickable ?
                    <div className="authorize-wrapper">
                      <img src={content.authorize} className="image" alt={content.authorize}/>
                    </div>
                  :
                    <a 
                      href="https://www.authorize.net/"
                      rel="noopener noreferrer"
                      target="_blank" 
                      className="authorize-wrapper"
                    >
                      <img src={content.authorize} className="image" alt={content.authorize}/> 
                    </a>
                }
              </React.Fragment>
            }
            {
              content.ApplyNowButton !== undefined &&
                <div className="button-holder">
                  <button 
                    className="btn-orange"
                    data-paperform-id="billsbykickstart" 
                    data-popup-button="1">
                      Apply now
                    </button>
                </div>
            }
          </div>
          <div className="image-holder">
            {
              content.image &&
              content.svg ?
              <img src={content.image} className="image" alt={content.imageName}/>
              :
              <Img filename={content.image} className="image" alt={content.imageName}/>
            }
            {
              content.comingSoon ? <button className="adoric_roadmap btn-gray">COMING SOON</button> : ''
            }
          </div>
          {
              content.tags && 
              <div className="tags">
                {content.tags.map((item, i) => {
                  return (
                    <button key={i} className={`${item.class} btn-gray btn-transparent`}>
                      {
                        item.iconDisable ? '' : <FontAwesomeIcon icon={faPlay} className="icon" />
                      }
                      {item.text}
                    </button> 
                  )
                })}
              </div>
            }
        </div>
      </div>
    </div>
  )
}
export default TwoColumn